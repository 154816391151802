/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

var map = null;

(function($) {

  var Func = {
   'init_maps': function() {
    $('.acf-map').each(function(){
      map = Func.new_map( $(this) );
    });
   },
   /*
    *  new_map
    *
    *  This function will render a Google Map onto the selected jQuery element
    *
    *  @type  function
    *  @date  8/11/2013
    *  @since 4.3.0
    *
    *  @param $el (jQuery element)
    *  @return  n/a
    */
    'new_map': function( $el ) {
      // var
      var $markers = $el.find('.marker');
      
      // vars
      var args = {
        zoom    : 16,
        center    : new google.maps.LatLng(0, 0),
        mapTypeId : google.maps.MapTypeId.ROADMAP
      };
      
      // create map           
      var map = new google.maps.Map( $el[0], args);
      
      // add a markers reference
      map.markers = [];
      
      // add markers
      $markers.each(function(){
          Func.add_marker( $(this), map );
      });
      
      // center map
      Func.center_map( map );
      
      // return
      return map;
    },
    /*
    *  add_marker
    *
    *  This function will add a marker to the selected Google Map
    *
    *  @type  function
    *  @date  8/11/2013
    *  @since 4.3.0
    *
    *  @param $marker (jQuery element)
    *  @param map (Google Map object)
    *  @return  n/a
    */
    'add_marker': function( $marker, map ) {
      // var
      var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );

      // create marker
      var marker = new google.maps.Marker({
        position  : latlng,
        map     : map
      });

      // add to array
      map.markers.push( marker );

      // if marker contains HTML, add it to an infoWindow
      if( $marker.html() )
      {
        // create info window
        var infowindow = new google.maps.InfoWindow({
          content   : $marker.html()
        });

        // show info window when marker is clicked
        google.maps.event.addListener(marker, 'click', function() {

          infowindow.open( map, marker );

        });
      }
    },
    /*
    *  center_map
    *
    *  This function will center the map, showing all markers attached to this map
    *
    *  @type  function
    *  @date  8/11/2013
    *  @since 4.3.0
    *
    *  @param map (Google Map object)
    *  @return  n/a
    */
    'center_map': function( map ) {
      // vars
      var bounds = new google.maps.LatLngBounds();

      // loop through all markers and create bounds
      $.each( map.markers, function( i, marker ){

        var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );

        bounds.extend( latlng );

      });

      // only 1 marker?
      if( map.markers.length === 1 )
      {
        // set center of map
          map.setCenter( bounds.getCenter() );
          map.setZoom( 16 );
      }
      else
      {
        // fit to bounds
        map.fitBounds( bounds );
      }
    }
  };

  var Sage = {
    // All pages
    'common': {
      init: function() {
        // Func.init_maps();
      },
      finalize: function() {
        
      }
    },
    'term_primary': {
      init: function() {



        var $school_areas = $(".competitions-location-links, .psaf-result");
        var $schools_list = $(".psaf-schools-list");
        var $schools_result = $(".psaf-result");

        // Store school area in cookie.
        $school_areas.on("click", ".btn-school-area", function(e){
          var $btn = $(this);
          var school_area = $btn.data("school_area");
          var school_area_name = $btn.data("school_area_name");

          $.removeCookie("school_area");
          $.removeCookie("school_area_name");

          $.cookie("school_area", school_area, { expires: 7, path: '/' });
          $.cookie("school_area_name", school_area_name, { expires: 7, path: '/' });
        });

        // Display last clicked school area.
        if($.cookie("school_area") && $.cookie("school_area_name")){
          $schools_result.append("You most recently clicked on the <a href='/competition-type/primary/" + $.cookie("school_area") + "/' data-school_area='" + $.cookie("school_area") + "' data-school_area_name='" + $.cookie("school_area_name") + "' class='btn-school-area'>" + $.cookie("school_area_name") + "</a> area.");
        }

        // Choose your school dropdown.
        $schools_list.select2({
          placeholder: "Choose your school...",
          allowClear: true
        });
        $schools_list.on("change", function(){

          var school_id = $schools_list.find("option:selected").val();

          $schools_result.html("<p><i class='fa fa-cog fa-spin fa-2x fa-fw'></i></p>");

          $.ajax({
            type:     "post",
            dataType: "json",
            url:      ajax_vars.ajax_url,
            data:     {
                        action: "get_school_area",
                        school_id : school_id
                      },
            success:  function(response){
                        if(response.status && response.message){
                          $schools_result.html("<p>" + response.message + "</p>");

                          // if(response.obj && response.obj.lat && response.obj.lng){
                          //   var args = {
                          //     zoom:       16,
                          //     center:     new google.maps.LatLng(0, 0),
                          //     mapTypeId:  google.maps.MapTypeId.ROADMAP
                          //   };
                          //   var $el = $(".acf-map");
                          //   var map = new google.maps.Map( $el[0], args);
                          //   var latlng = new google.maps.LatLng( response.obj.lat, response.obj.lng );
                          //   var marker = new google.maps.Marker({
                          //     position:   latlng,
                          //     map:        map
                          //   });

                          //   // add a markers reference
                          //   map.markers = [];

                          //   // add to array
                          //   map.markers.push( marker );

                          //   // center map
                          //   Func.center_map( map );
                          // }
                        }
                        else{
                          $schools_result.html("<p>Sorry, we couldn't determine your school's area.</p>");
                        }
                      }
          });
        });
      },
      finalize: function() {
        
      }
    },
    'our_schools': {
      init: function() {
        $(".competitions-location-links").on("click", ".btn-school-area", function(e){
          e.preventDefault();
          e.stopPropagation();

          var $button = $(this);
          var area_slug = $button.data("school_area");
          var $schools_result = $(".our-schools-results");

          $schools_result.html("<p><i class='fa fa-cog fa-spin fa-2x fa-fw'></i></p>");

          $.ajax({
            type:     "post",
            dataType: "json",
            url:      ajax_vars.ajax_url,
            data:     {
                        action: "get_schools_in_area",
                        area_slug : area_slug
                      },
            success:  function(response){
                        if(response.status && response.message){
                          if(response.status == "success"){
                            $schools_result.html("");

                            if(response.area_name.length){
                              $schools_result.html("<h1>" + response.area_name + "</h1>");
                            }

                            if(response.primary_schools.length){
                              $schools_result.append("<h2>Primary Schools</h2><ul class='our-schools-primary-list'></ul>");

                              $.each(response.primary_schools, function(key, val){
                                $schools_result.append("<li>" + val.school_name + "</li>");
                              });
                            }

                            if(response.secondary_schools.length){
                              $schools_result.append("<h2>Secondary Schools</h2><ul class='our-schools-secondary-list'></ul>");

                              $.each(response.secondary_schools, function(key, val){
                                $schools_result.append("<li>" + val.school_name + "</li>");
                              });
                            }
                          }
                          else{
                            $schools_result.html("<p>Sorry, we couldn't get a list of schools for the chosen area.</p>");
                          }
                        }
                        else{
                          $schools_result.html("<p>Sorry, we couldn't get a list of schools for the chosen area.</p>");
                        }
                      }
          });
        });
      },
      finalize: function() {
        
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
